import * as React from "react"


import Layout from "../components/layout"

export default function HomePage() {
    return (
        <Layout>

    </Layout>
);
}
 
