
import React from "react"
import * as headerStyles from "../styles/header.module.css"

export default function Header() {
    return (                      
        <div className={headerStyles.topPane}>
        <div className={headerStyles.header}>
        <div className={headerStyles.logo}></div>
        <div className={headerStyles.menu}>
        <div className={headerStyles.menuItem}>Компетентност</div>
    <div className={headerStyles.menuItem}>Услуги</div>
    <div className={headerStyles.menuItem}>Индустрии</div>
        <div className={headerStyles.menuItem}>Компания</div>


        </div>
        <div>

    <div className={headerStyles.startProject}>Започнете проект</div>
    <div className={headerStyles.helpDesk}>Поддръжка</div>
    </div>
        </div>
</div>
)
}