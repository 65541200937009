
import React from "react"
import Header from "../components/header"

import * as viewStyles from "../styles/layout.module.css"

export default function Layout({ children }) {
    return (
        <div className={viewStyles.view}>
        <Header />
    {children}
</div>
)
}